import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
import { navigate, Router } from '@reach/router';

import Header from './header';
import Callback from '../routes/callback'
import { Cache } from './contexts';
import LoginLogout from './loginlogout'

import Auth from '../auth/Auth'

// Code-splitting is automated for routes
import Home from '../routes/home';
import Profile from '../routes/profile';

const auth = new Auth()

export default class App extends Component {
  /** Gets fired when the route changes.
   *  @param {Object} event        "change" event from [preact-router](http://git.io/preact-router)
   *  @param {string} event.url    The newly routed URL
   */
  handleRoute = e => {
    this.currentUrl = e.url;
  };
  
  render() {
    return <>
      <Header />
      <div id="app">
        <Router onChange={this.handleRoute} >
          <Callback path="/callback" auth={auth} />
          <LoginLogout path="/login" auth={auth}/>
          <Home path="/" auth={auth} />
          <Profile path="/profile/" user="me" auth={auth} />
          <Profile path="/profile/:user" auth={auth} />
        </Router>
      </div>
    </>;
  }
}
