import { h } from 'preact';

const handleLogin = (auth) => {
  auth.login()
}

const handleLogout= (auth) => {
  auth.logout()
}

const logInLogOutButton = (auth) => {
  if (auth.isAuthenticated()) {
    return (
      <div onClick={() => handleLogout(auth)}>
        Log Out
      </div>
    )
  } else {
    return (
      <div onClick={() => handleLogin(auth)}>
        Log In
      </div>
    )
  }
}

const Header = ({ auth }) => (
    <>
        {logInLogOutButton(auth)}
    </>
);

export default Header;
